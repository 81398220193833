import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Button from "@material-ui/core/Button";

const LinkButton = (props) => {
  const {
    history,
    location,
    match,
    staticContext,
    to,
    onClick,
    color,
    // ⬆ filtering out props that `button` doesn’t know what to do with.
    ...rest
  } = props;

  const handleOnClick = (event) => {
    if (onClick) {
      onClick(event);
    }
    history.push(to);
  };

  return (
    <button
      type="button"
      className="block w-full rounded-md border border-transparent px-5 py-3 bg-indigo-600 text-base font-medium text-white shadow hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:px-10 uppercase"
      onClick={(event) => handleOnClick(event)}
    >
      Sign up for beta
    </button>
  );
};

LinkButton.propTypes = {
  // to: PropTypes.string.isRequired, // Can also pass href...
  children: PropTypes.node.isRequired,
};

export default withRouter(LinkButton);
