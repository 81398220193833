import {
  AppBar,
  Toolbar,
  Button,
  makeStyles,
  IconButton,
  Drawer,
  MenuItem,
  Link,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ASOGiraffeLogoNew from "./assets/asogiraffe-logo.svg";
import { Link as ReactLink } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";

const headersData = [
  {
    label: "Blog",
    href: "https://blog.asogiraffe.com",
    component: "a",
  },
  {
    label: "Test your app concepts",
    href: "https://asogiraffe.com",
    component: "a",
  },
  {
    /*{
    label: "Login",
    href: "/login",
    component: ReactLink,
  },*/
  },
];

export default function Header() {
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1000
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <img
          className="h-12 mt-2 mb-2"
          alt="ASO Giraffe Logo"
          src={ASOGiraffeLogoNew}
        />
        <div>{getMenuButtons()}</div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));

    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>
        <img
          className="h-12 mt-2 mb-2"
          alt="ASO Giraffe Logo"
          src={ASOGiraffeLogoNew}
        />
      </Toolbar>
    );
  };

  const useStyles = makeStyles(() => ({
    header: {
      flexGrow: 1,
      background: "linear-gradient(90deg, #E65100 0%, #FF9800 100%)",
      fontFamily: "Circular Std Medium, sans-serif",
      paddingRight: "79px",
      paddingLeft: "118px",
      "@media (max-width: 1000px)": {
        paddingLeft: 0,
      },
    },
    menuButton: {
      fontFamily: "Circular Std Medium, sans-serif",
      fontWeight: 700,
      size: "18px",
      marginLeft: "100px",
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
    },
    drawerContainer: {
      padding: "20px 30px",
    },
  }));

  const { header, menuButton, toolbar, drawerContainer } = useStyles();

  const getMenuButtons = () => {
    return headersData.map(({ label, href, component }) => {
      return (
        <Button
          {...{
            target: "_blank",
            key: label,
            href: href,
            color: "inherit",
            component: component,
            className: menuButton,
          }}
        >
          {label}
        </Button>
      );
    });
  };
  const getDrawerChoices = () => {
    return headersData.map(({ label, href, component }) => {
      return (
        <Link
          {...{
            target: "_blank",
            key: label,
            href: href,
            color: "inherit",
            style: { textDecoration: "none" },
          }}
        >
          <MenuItem>{label}</MenuItem>
        </Link>
      );
    });
  };

  return (
    <header>
      <AppBar className={header}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}
