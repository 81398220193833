import { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import ExperimentsList from "./ExperimentsList";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "hidden",
    borderRadius: 10,
    borderBottom: "none",
  },
  table: {
    minWidth: 450,
  },
  head: {
    backgroundColor: "#E8EAF6",
  },
  headCell: {
    fontSize: 18,
    letterSpacing: 0.15,
    color: "#868896",
    padding: "12px 16px",
  },
  cell: {
    fontSize: 14,
    padding: "20px 16px",
    textColor: "black",
  },
  winnerCell: {
    paddingRight: 0,
  },
  versionCell: {
    minWidth: 200,
    width: 200,
  },
  variantCell: {
    display: "flex",
    alignItems: "center",
  },
  variant: {
    marginRight: 10,
  },
});

export default function Account({ session }) {
  const [loading, setLoading] = useState(true);
  const [experiment_name, setExperimentName] = useState(null);
  const [url_1, setUrl1] = useState(null);
  const [url_2, setUrl2] = useState(null);
  const [url_3, setUrl3] = useState(null);
  const [url_4, setUrl4] = useState(null);
  const [display_url, setDisplayUrl] = useState(null);
  const [experiments_list, setExperimentsList] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    getExperiments();
  }, [session]);

  const classes = useStyles();

  async function getExperiments() {
    try {
      setLoading(true);
      const user = supabase.auth.user();

      let { data, error, status } = await supabase
        .from("experiments")
        .select(`id, name, url_1, url_2, url_3, url_4, page_uri`)
        .eq("user_id", user.id)
        .order("id", { ascending: false });

      if (error && status !== 406) {
        throw error;
      }

      console.log(data);

      if (data) {
        setExperimentsList(data);
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function addExperiment({
    experiment_name,
    url_1,
    url_2,
    url_3,
    url_4,
    display_url,
  }) {
    try {
      setLoading(true);
      const user = supabase.auth.user();

      //generate random hex for page URI
      const genRanHex = (size) =>
        [...Array(size)]
          .map(() => Math.floor(Math.random() * 16).toString(16))
          .join("");

      const url_suffix = genRanHex(6);

      const uri =
        experiment_name.replace(/[^A-Z0-9]/gi, "_") + "-" + url_suffix;

      const addition = {
        user_id: user.id,
        name: experiment_name,
        url_1: url_1,
        url_2: url_2,
        url_3: url_3,
        url_4: url_4,
        page_uri: uri,
        redirect_url: display_url,
        inserted_at: new Date(),
      };

      let { error } = await supabase.from("experiments").insert(addition, {
        returning: "minimal", // Don't return the value after inserting
      });

      //need to update public table as well
      const url_addition = {
        user_id: user.id,
        name: experiment_name,
        url_1: url_1,
        url_2: url_2,
        url_3: url_3,
        url_4: url_4,
        page_uri: uri,
        redirect_url: display_url,
        inserted_at: new Date(),
      };

      let { error2 } = await supabase
        .from("experiments_urls")
        .insert(url_addition, {
          returning: "minimal", // Don't return the value after inserting
        });

      if (error) {
        throw error;
      }
    } catch (error) {
      alert(error.message);
    } finally {
      getExperiments();
    }
  }

  return (
    <div>
      <div>
        <button
          className=" float-right bg-transparent text-black hover:bg-blue hover:text-black text-blue-dark font-semibold hover:text-white py-2 px-4 border border-blue hover:border-transparent rounded"
          onClick={() => supabase.auth.signOut()}
        >
          Sign Out
        </button>
      </div>
      <br className="mb-5" s />
      <h2 className="text-black">Add Experiment</h2>
      <div className="form-widget">
        <div>
          <label htmlFor="experiment_name">Experiment Name</label>
          <input
            id="experiment_name"
            type="text"
            className="text-black"
            placeholder="Enter the name of your experiment"
            onChange={(e) => setExperimentName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="base_url">Product Page #1</label>
          <input
            id="url_1"
            type="website"
            className="text-black"
            placeholder="Your product page URL"
            onChange={(e) => setUrl1(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="url_1">Product Page #2</label>
          <input
            id="url_2"
            type="website"
            className="text-black"
            placeholder="Your product page URL"
            onChange={(e) => setUrl2(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="url_1">Product Page #3</label>
          <input
            id="url_3"
            type="website"
            className="text-black"
            placeholder="Your product page URL"
            onChange={(e) => setUrl3(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="url_1">Product Page #4</label>
          <input
            id="url_4"
            type="website"
            className="text-black"
            placeholder="Your product page URL"
            onChange={(e) => setUrl4(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="url_1">Display URL (this will be shown alongside your ad on Facebook) </label>
          <input
            id="display_url"
            type="website"
            className="text-black"
            placeholder="Display URL"
            onChange={(e) => setDisplayUrl(e.target.value)}
          />
        </div>
        <div>
          <button
            className="text-white hover:bg-blue text-blue-dark font-semibold hover:text-white bg-purple-700 py-2 px-4 border border-blue hover:border-transparent rounded"
            onClick={() =>
              addExperiment({ experiment_name, url_1, url_2, url_3, url_4, display_url })
            }
            disabled={loading}
          >
            {loading ? "Loading ..." : "Add Experiment"}
          </button>
        </div>
      </div>
      <br />
      <div>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className={classes.head}>
              <TableCell
                className={clsx(classes.headCell, classes.versionCell)}
              >
                Experiment Name
              </TableCell>
              <TableCell align="center" className={classes.headCell}>
                Redirect URLs
              </TableCell>
              <TableCell align="center" className={classes.headCell}>
                Experiment URL
              </TableCell>
              <TableCell
                align="center"
                className={clsx(classes.headCell, classes.versionCell)}
              >
                Delete
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {experiments_list.map((experiment) => (
              <ExperimentsList
                key={experiment.id}
                experiment_id={experiment.id}
                name={experiment.name}
                url_1={experiment.url_1}
                url_2={experiment.url_2}
                url_3={experiment.url_3}
                url_4={experiment.url_4}
                page_uri={experiment.page_uri}
                get_experiments={getExperiments}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
