import React, {
  useImperativeHandle,
  useState,
  forwardRef,
  useMemo,
} from "react";
import {
  Container,
  Modal,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { sendScheduleDemoData } from "../../../utils/sendScheduleDemoData";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.8rem",
    fontWeight: "700",
  },
  button: {
    borderRadius: "20px",
    minWidth: 245,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalInner: {
    width: "50%",
    minWidth: 350,
  },
  modalHeader: {
    backgroundColor: "#E8EAF5",
  },
  grid: {
    marginTop: "20px",
  },
  formWrapper: {
    height: 'calc(100vh - 118px)',
    overflow: 'scroll',
    width: '50%',
    minWidth: '350px',
    '@media (max-width: 767px)': {
      height: '100vh',
      padding: '10px 0',
    },
  }
}));

export function DemoModal({ children, defaultOpened = false }, ref) {
  const classes = useStyles();

  const [modalOpened, setModalOpened] = useState(defaultOpened);

  useImperativeHandle(
    ref,
    () => ({
      open: () => setModalOpened(true),
      close: () => setModalOpened(false),
    }),
    []
  );

  const handleModalClose = () => {
    setModalOpened(false);
  };

  const [isAgreed, setIsAgreed] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const isFormValid = useMemo(() => {
    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !company.trim() ||
      !email.trim()
    ) {
      return false;
    }

    return true;
  }, [firstName, lastName, company, email, isAgreed]);

  const handleSubmitClick = (e) => {
    e.preventDefault();

    sendScheduleDemoData(email, firstName, lastName, company, message);
    handleModalClose();
  };

  return (
    <Container>
      <Modal
        open={modalOpened}
        onClose={handleModalClose}
        aria-labelledby="demo-form-modal"
        aria-describedby="submit-a-request-for-a-demo"
        className={classes.modal}
      >
        <div className={classes.formWrapper}>
        <Card className={classes.modalInner}>
          <CardHeader title="Beta Signup" className={classes.modalHeader} />
          <CardContent className={classes.margin}>
            <form
              onSubmit={handleSubmitClick}
              className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
              <div>
                <label
                  for="first-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  First name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autocomplete="given-name"
                    className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    value={firstName}
                    required
                  />
                </div>
              </div>
              <div>
                <label
                  for="last-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autocomplete="family-name"
                    className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    value={lastName}
                    required
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  for="company"
                  className="block text-sm font-medium text-gray-700"
                >
                  Company
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="company"
                    id="company"
                    autocomplete="organization"
                    className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    onChange={(e) => {
                      setCompany(e.target.value);
                    }}
                    value={company}
                    required
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  for="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                    required
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  for="message"
                  className="block text-sm font-medium text-gray-700"
                >
                  Message
                </label>
                <div className="mt-1">
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    value={message}
                  ></textarea>
                </div>
              </div>
              <div className="sm:col-span-2">
                <button
                  disabled={!isFormValid}
                  type="submit"
                  className={`${
                    isFormValid && "hover:bg-indigo-700"
                  } w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-auto`}
                >
                  Let's talk
                </button>
              </div>
            </form>
          </CardContent>
        </Card>
        </div>
      </Modal>
    </Container>
  );
}

export default forwardRef(DemoModal);
