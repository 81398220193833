import { useState, useRef } from "react";
import { supabase } from "./supabaseClient";

import ScheduleDemoForm from "./shared/components/ScheduleDemoForm";
import DemoModal from "./shared/marketing/components/DemoModals/DemoModal";

import FabulousLogoGrey from "./assets/fabulous-grey.svg";
import HotSpotLogoGrey from "./assets/hotspot-grey.svg";
import SuperSolidLogoGrey from "./assets/supersolid-grey.svg";
import FabulousLogo from "./assets/fabulous-logo.svg";
import KevinFabulous from "./assets/kevin-chu-fabulous.png";
import { LinkButton } from "./shared/components/LinkButton";

//import Analytics from "./assets/analytics.png";
//import ExperimentPage from "./assets/experiment-page.png";
//import KevinFabulous from "./assets/kevin-chu-fabulous.png";

export default function Auth() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleLogin = async (email) => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.signIn(
        {
          email,
        },
        {
          redirectTo: "http://localhost:3000/",
        }
      );
      if (error) throw error;
      alert("Check your email for the login link!");
    } catch (error) {
      alert(error.error_description || error.message);
    } finally {
      setLoading(false);
    }
  };

  const modal = useRef(null);

  return (
    <div className="bg-white pb-8 sm:pb-12 lg:pb-12">
      <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-28">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl flex flex-wrap flex-col lg:flex-row items-start lg:items-center">
          <div className="w-full lg:pr-12">
            <div className="mt-20 lg:mt-0">
              <div className="sm:max-w-xl">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  Make the most of your Product Pages
                </h1>
                <p className="mt-6 text-xl text-gray-500">
                  Run A/B tests natively. Send traffic to your Custom Product
                  Pages from Facebook, before anyone else
                </p>
              </div>
              <div className="mt-12 sm:max-w-lg sm:w-full sm:flex">
                <div className="flex justify-center">
                  <LinkButton
                    onClick={() => modal.current.open()}
                    color="primary"
                    variant="contained"
                    size="large"
                    className="w-1/2 pl-0 ml-8"
                  ></LinkButton>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 mt-24 lg:mt-0 lg:pl-12">
            <img />
          </div>
        </div>
      </div>
      <div class="bg-white bg-opacity-25">
        <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div class="lg:grid lg:grid-cols-2 lg:gap-8">
            <h2 className="max-w-md mx-auto text-3xl font-extrabold text-indigo-900 text-center lg:max-w-xl lg:text-left">
              Trusted by the biggest app developers and publishers
            </h2>
            <div class="flow-root self-center mt-8 lg:mt-0">
              <div class="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
                <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                  <img class="h-10" src={HotSpotLogoGrey} alt="Workcation" />
                </div>
                <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                  <img class="h-10" src={FabulousLogoGrey} alt="Tuple" />
                </div>
                <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                  <img class="h-10" src={SuperSolidLogoGrey} alt="Level" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="py-12 bg-gray-600 overflow-hidden md:py-20 lg:py-24">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative">
            <img className="mx-auto h-8" src={FabulousLogo} alt="Fabulous" />
            <blockquote className="mt-10">
              <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium">
                <p className="text-white">
                  &ldquo;Our objective was to improve our overall impression to
                  install conversion. Prior to using ASO Giraffe, we were just
                  applying wins across Play Store on the App Store, but soon
                  learnt it doesn't always translate&rdquo;
                </p>
              </div>
              <footer className="mt-8">
                <div className="md:flex md:items-center md:justify-center">
                  <div className="md:flex-shrink-0">
                    <img
                      className="mx-auto h-10 w-10 rounded-full"
                      src={KevinFabulous}
                      alt=""
                    />
                  </div>
                  <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div className="text-base font-medium text-white">
                      Kevin Chu
                    </div>

                    <svg
                      className="hidden md:block mx-1 h-5 w-5 text-indigo-600"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div className="text-base font-medium text-gray-50">
                      Marketing Lead, The Fabulous
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>
      <br />
      <div className="mt-4 sm:mt-0">
        <p className="description text-gray-500 mb-2">
          Sign in via magic link with your email below
        </p>
        <div>
          <input
            className="text-black mb-5"
            type="email"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleLogin(email);
            }}
            type="button"
            className={
              "block w-full rounded-md border border-transparent px-5 py-3 bg-indigo-600 text-base font-medium text-white shadow hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:px-10 uppercase"
            }
            disabled={loading}
          >
            {loading ? (
              <span className="text-white">Loading</span>
            ) : (
              <span className="text-white">Send magic link</span>
            )}
          </button>
        </div>
      </div>
      <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
        <div className="relative max-w-xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Contact sales
            </h2>
            <p className="mt-4 text-lg leading-6 text-gray-500">
              Want to see the platform before committing or ask any more
              questions? Get in touch!
            </p>
          </div>
          <div className="mt-12">
            <ScheduleDemoForm />
            <DemoModal ref={modal} />
          </div>
        </div>
      </div>
    </div>
  );
}
