import React, { useState, useMemo } from "react";
import { sendScheduleDemoData } from "../utils/sendScheduleDemoData";

const ScheduleDemoForm = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const isFormValid = useMemo(() => {
    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !company.trim() ||
      !email.trim()
    ) {
      return false;
    }

    return true;
  }, [firstName, lastName, company, email, isAgreed]);

  const handleSubmitClick = (e) => {
    e.preventDefault();

    sendScheduleDemoData(email, firstName, lastName, company, message);
  };

  return (
    <form
      onSubmit={handleSubmitClick}
      className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
    >
      <div>
        <label
          for="first-name"
          className="block text-sm font-medium text-gray-700"
        >
          First name
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="first-name"
            id="first-name"
            autocomplete="given-name"
            className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            value={firstName}
            required
          />
        </div>
      </div>
      <div>
        <label
          for="last-name"
          className="block text-sm font-medium text-gray-700"
        >
          Last name
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="last-name"
            id="last-name"
            autocomplete="family-name"
            className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            value={lastName}
            required
          />
        </div>
      </div>
      <div className="sm:col-span-2">
        <label
          for="company"
          className="block text-sm font-medium text-gray-700"
        >
          Company
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="company"
            id="company"
            autocomplete="organization"
            className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
            onChange={(e) => {
              setCompany(e.target.value);
            }}
            value={company}
            required
          />
        </div>
      </div>
      <div className="sm:col-span-2">
        <label for="email" className="block text-sm font-medium text-gray-700">
          Email
        </label>
        <div className="mt-1">
          <input
            id="email"
            name="email"
            type="email"
            autocomplete="email"
            className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            required
          />
        </div>
      </div>
      <div className="sm:col-span-2">
        <label
          for="message"
          className="block text-sm font-medium text-gray-700"
        >
          Message
        </label>
        <div className="mt-1">
          <textarea
            id="message"
            name="message"
            rows="4"
            className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            value={message}
          ></textarea>
        </div>
      </div>
      <div className="sm:col-span-2">
        <button
          disabled={!isFormValid}
          type="submit"
          className={`${
            isFormValid && "hover:bg-indigo-700"
          } w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-auto`}
        >
          Let's talk
        </button>
      </div>
    </form>
  );
};

export default ScheduleDemoForm;
