export const sendScheduleDemoData = (
  email,
  firstName,
  lastName,
  company,
  message
) => {
  const json = JSON.stringify({
    fields: [
      {
        name: "email",
        value: email,
      },
      {
        name: "firstname",
        value: firstName,
      },
      {
        name: "lastname",
        value: lastName,
      },
      {
        name: "company",
        value: company,
      },
      {
        name: "message",
        value: message,
      },
    ],
    context: {
      pageUri: window.location.pathname,
      pageName: document.title,
    },
  });

  const url =
    "https://api.hsforms.com/submissions/v3/integration/submit/8708199/f32964c8-bbc5-4b9f-9fcb-d555a4f6ef76";

  fetch(url, {
    method: "post",
    headers: {
      "Content-type": "application/json",
    },
    body: json,
  })
    .then(json)
    .then(function (data) {
      console.log("Request succeeded with JSON response", data);
    })
    .catch(function (error) {
      console.log("Request failed", error);
    });
  console.log("form submitted!");
  alert("Your data has been successfully received!");
};
