import { React, useState } from "react";
import { supabase } from "./supabaseClient";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

function ExperimentsList(props) {
  var [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  url = "https://redirecter.asogiraffe5600.workers.dev/" + props.page_uri;

  const experiments_array = [
    props.url_1,
    props.url_2,
    props.url_3,
    props.url_4,
  ];

  const filtered_array = experiments_array.filter((n) => n);

  async function deleteExperiment(experiment_id) {
    try {
      console.log(experiment_id);
      setLoading(true);
      const user = supabase.auth.user();

      const { data, error } = await supabase
        .from("experiments")
        .delete()
        .eq("user_id", user.id)
        .match({ id: experiment_id });

      if (error) {
        throw error;
      }
    } catch (error) {
      alert(error.message);
    } finally {
      {
        props.get_experiments();
        setLoading(false);
      }
    }
  }

  return (
    <TableRow>
      <TableCell>{props.name}</TableCell>

      <TableCell align="center">
        {filtered_array.map((e, i) => (
          <ul>
            <li className="text-left text-black" key={i}>
              {e} {}
            </li>
          </ul>
        ))}
      </TableCell>

      <TableCell className="max-width-20" align="center">
        <div align="center" className="max-width-100">
          <button
            className="text-white bg-purple-600 hover:bg-purple-800 rounded active:bg-white active:text-purple-800 font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            onClick={() => {
              navigator.clipboard.writeText(url);
            }}
          >
            Copy URL
          </button>
        </div>
      </TableCell>
      <TableCell align="center">
        <button
          className="text-purple-700 background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          onClick={() => deleteExperiment(props.experiment_id)}
        >
          Delete Experiment
        </button>
      </TableCell>
    </TableRow>
  );
}

export default ExperimentsList;
